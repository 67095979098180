import React, { useState, useEffect } from 'react';
import IntroDescription from './components/IntroDescription/IntroDescription';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import NewPledge from './components/Pledges/NewPledge';
import PledgeList from './components/Pledges/PledgeList';
import Summary from './components/Summary/Summary';
import LoadingSpinner from './components/UIElements/LoadingSpinner'
import VerticalModal from './components/UIElements/VerticalModal'
import {Row, Container} from 'react-bootstrap'
import './App.css';

function App() {
  const [loadedPledges, setLoadedPledges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorState,setErrorState ]= useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [newSubmission, setSubmission] = useState(false);

  useEffect(() => {
    const fetchPledges = async () => {
      let response;
      try{
        setIsLoading(true);
        response = await fetch(process.env.REACT_APP_BACKEND_URL + '/pledges');
        // console.log(error)
      
        const responseData = await response.json();
        if (!response.ok) {
          throw new Error(responseData.message)
        }
        setIsLoading(false);
        setLoadedPledges(responseData.pledges);
      }
      catch (error){
        setIsLoading(false);
        setErrorState(error.message || 'Something went wrong. Please try again');
      }

    };
    fetchPledges();
  }, []);

  const addPledgeHandler = async (Name, Email, pledgeAmount, pledgeState, pledgeOrg) => {
    try {
      const newPledge = {
        name: Name,
        email: Email,
        pledge: +pledgeAmount, // "+" to convert string to number
        state: pledgeState, 
        org: pledgeOrg,
      };
      let hasError = false;
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/pledge', {
        method: 'POST',
        body: JSON.stringify(newPledge),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        hasError = true;
      }

      const responseData = await response.json();

      if (hasError) {
        throw new Error(responseData.message);
      }
      setLoadedPledges(prevPledges => {
        return prevPledges.concat({
          ...responseData.pledge
        });
      });
      setSubmission(true)
    } catch (error) {
      setModalShow(true);
    }
  };

  const handleModalChange =event => {
    setModalShow(false)
  }
  const pledges = loadedPledges.length;
  const totalamountpledged = loadedPledges.reduce((sum, pledge) => sum + pledge.pledge, 0);
  // console.log(loadedPledges.map((pledge) => pledge.email))
  // console.log(loadedPledges.map((pledge)=>pledge.pledge))
  // console.log(loadedPledges)
  return (
    <React.Fragment>
      <Header />
      <main>
      <Container> 
        <Row className="justify-content-center firstrow">
         <IntroDescription />
         <NewPledge submitted={newSubmission} onAddPledge={addPledgeHandler} />
       <VerticalModal show={modalShow} onHide={handleModalChange} />
         </Row>
         </Container>
         <Row className="justify-content-center">
         {!isLoading && !errorState && <Summary numberpledges={pledges} totalamountpledged={totalamountpledged}/>}
        </Row> 
        {(isLoading || errorState) && <h2 className="loader">Loading Data:</h2>}
        {(isLoading || errorState) && <LoadingSpinner asOverlay className="pledgeloader"/>}
        {!isLoading && !errorState && <PledgeList items={loadedPledges} />}

      </main>
      <Footer />
    </React.Fragment>
  );
}

export default App;
