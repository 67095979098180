import React from 'react';
import {Modal} from 'react-bootstrap'
import Button from '../Button/Button'

import './VerticalModal.css';

const VerticalModal = props => {
  return (
    <Modal show={props.show}  size="md" aria-labelledby="contained-modal-title-vcenter"
    centered>
    <Modal.Body>
      <h3>Oops, Something Went Wrong!</h3>
      <p>
    Something went wrong with adding your pledge. Do you want to try again? 
  </p>
    </Modal.Body>
    <Modal.Footer>
        <Button onClick={props.onHide} >Close</Button>
</Modal.Footer>
  </Modal>
  );
};

export default VerticalModal;
