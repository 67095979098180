import React from 'react';
import {Col, Row} from 'react-bootstrap'
import './PledgeItem.css';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const PledgeItem = props => {
  const r = Math.random()*255
  const g = Math.random()*255
  const b = Math.random()*255

  return (
    <Col xs ={6} sm={4} md={3} className="pledge-item">
      <Row className="justify-content-center"><AccountCircleIcon style={{color: `rgb(${r},${g},${b})`}} id="person-icon" /></Row>
      <Row className="justify-content-center"><h2>{props.name}</h2></Row>
      <Row className="justify-content-center pledge-line"><p>Pledged: <span className="pledged-amount">${props.pledge}</span></p></Row>
      <Row className="justify-content-center org-line">{props.org && <p>for <span className="pledgedorg">{props.org}</span></p>}</Row>
    </Col>
  );
};

export default PledgeItem;
