import React from 'react';
import {Container} from 'react-bootstrap'

import './Header.css';

const Header = props => {
  return (
    <header className="header">
      <Container className="headerholder">
      <h1>Pledge Your Stimulus</h1>
      </Container>
    </header>
  );
};

export default Header;
