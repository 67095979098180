import React from 'react';
import {Container} from 'react-bootstrap'

import './Footer.css';


const Footer = props => {
  return (
     <footer className="footer">
      <Container>
        <p className="contact">Contact us at pledgeyourstimulus@gmail.com if you have any questions/comments/concerns!</p>
      </Container>
    </footer>
  );
};

export default Footer;
