import React from 'react';
import {Row, Col} from 'react-bootstrap'
import Input from '../Input/Input';
import Button from '../Button/Button';
import {EmailShareButton,FacebookShareButton,TwitterShareButton,WhatsappShareButton, LinkedinShareButton} from 'react-share'
import {EmailIcon, FacebookIcon, TwitterIcon, WhatsappIcon, LinkedinIcon} from 'react-share'
import { VALIDATOR_REQUIRE , VALIDATOR_MIN, VALIDATOR_EMAIL, VALIDATOR_MINLENGTH} from '../../shared/Validators'
import StateData from '../../shared/statesdata'
import './NewPledge.css';


const NewPledge = props => {
  let enteredName=''
  let enteredPledge=''
  let enteredEmail = ''
  let enteredOrg = ''
  let enteredState = ''
  let isPledgeValid = ''
  let isEmailValid=''


  const nameChangeHandler = (valid, value) => {
    enteredName = value===''? "Anonymous" : value
  };

  const pledgeChangeHandler = (valid, value) => {
    isPledgeValid=valid
    enteredPledge = value
  };

  const emailChangeHandler = (valid, value) => {
    isEmailValid=valid
    enteredEmail = value
  };

  const orgChangeHandler = (valid, value) =>{
    enteredOrg = value
    // setEnteredOrg(value)
  }

  const stateChangeHandler = (valid, value)=>{
    enteredState = value;
  }


  const submitPledgeHandler = event => {
    event.preventDefault();
    if (isPledgeValid && isEmailValid) {
      props.onAddPledge(enteredName, enteredEmail, enteredPledge, enteredState, enteredOrg);
      // setSubmitted(true)
    }
  };

  let content;
  const weburl = "https://pledgeyourstimulus.com"
  if(props.submitted){
    content = 
    <section className="submitted">
      <Row><h3>Thank you for making a pledge! We will reach out to you over email to confirm your pledge.</h3></Row>
      <Row><h3>Share our page on Social Media to raise more awareness! </h3></Row>
      <Row className="justify-content-center" noGutters={true}>
      {/* <Col xs={11} sm={8} md={7} > */}
        {/* <Row> */}
       <FacebookShareButton url={weburl} ><FacebookIcon size={32} round /></FacebookShareButton>
       <TwitterShareButton url={weburl} ><TwitterIcon size={32} round /></TwitterShareButton>
       <LinkedinShareButton url={weburl} ><LinkedinIcon size={32} round /></LinkedinShareButton>
      <WhatsappShareButton url={weburl} ><WhatsappIcon size={32} round /></WhatsappShareButton>
      <EmailShareButton url={weburl}><EmailIcon size={32} round /></EmailShareButton>
      {/* </Row></Col>  */}
      </Row>

    </section>
  }
  else {
    content= <section><Row className="pledgeheader"><h2>Make a Pledge!</h2></Row>
    <form onSubmit={submitPledgeHandler}>
      <Input
        type="text"
        label="Name"
        id="name"
        validators = {[]}
        onInputChange={nameChangeHandler}
      />
      <Input
        type="text"
        label="Email*"
        id="email"
        errorText="email."
        validators={[VALIDATOR_EMAIL(), VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(3)]}
        onInputChange={emailChangeHandler}
      />
      <Input
        type="number"
        label="Pledge Amount ($)*"
        step={1}
        id="pledge"
        errorText="pledge amount."
        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MIN(1)]}
        onInputChange={pledgeChangeHandler}
      />
       <Input
        label="State You Live In"
        id="state"
        validators={[]}
        data= {StateData}
        onInputChange={stateChangeHandler}
      />
      <span className="wide"><Input
        type="text"
        label="Where are you pledging the money to? (if you already know)"
        id="org"
        validators = {[]}
        onInputChange={orgChangeHandler}
      /></span>
      <Button type="submit">ADD PLEDGE</Button>
    </form>
    </section>
    
  }
  return (
    <Col xs={11} m={8} lg={6}>
    <section id="new-pledge">
      {content}
    </section>
    </Col>
  );
};

export default NewPledge;
