import React from "react";
import { Row, Col} from "react-bootstrap";
import CountUp from "react-countup";

import "./Summary.css";

const Summary = props => {
  let summary;
  if (!props || props.totalamountpledged === 0) {
    summary = <section id="summary"><h2>Will you make a pledge today?</h2></section>;
  } else {
    const persontext = props.numberpledges > 1 ? "people" : "person";

    summary = (
      <section id="summary">
        <Row className="justify-content-center">
          $
          <CountUp
            className="totalpledged"
            start={0}
            end={props.totalamountpledged}
            duration={2}
            separator=","
            decimals={0}
            // prefix="USD "
            // suffix=" left"
            delay={0}
          ></CountUp>
        </Row>

        <Row className="justify-content-center totalpledgedblock">
          <p>{" "}
          pledged so far by {props.numberpledges} {persontext}</p>
        </Row>
      </section>
    );
  }
  return <Col md={4} className="justify-content-center">{summary}</Col>;
};
export default Summary;
