import React from 'react';
import {Col} from 'react-bootstrap'
import './IntroDescription.css';

const IntroDescription = props => {
  return (
<Col md={12} lg={6}>
<section id="introdesc">
 <h1>Pledge Your Stimulus</h1>
 {/* <p> */}
   
     {/* Many of our lives have changed overnight because of coronavirus, but we know that the impact of that change is not the same for everyone. While some of our community can work from home, others 
     are unable to and still have to go to work. Some community members have seen reduced hours or lost their jobs completely. Others in our community were already facing precarious situations that are now just exacerbated by the impacts of coronavirus. 
     We know that many of our community organizations are also affected, ranging from food banks, arts institutions, to places of workshop. 
      */}
     {/* Many of us will be receiving stimulus checks from the government in the coming months, but not all of us have been impacted in the same way. We are asking those of you who are able to, to pledge to donate some portion of the money you will receive. </p> */}
     <p> Due to the global outbreak of COVID-19, many of us in the US will be receiving stimulus checks from the government in the coming months, but not all of us have been impacted in the same way.   
     <span className="imp"> We are asking those less impacted to pledge your stimulus, or a portion of it, to those who have been impacted the most. </span> If you are not receiving a stimulus, but still want to give, feel free to make a pledge and give whatever you can.
</p>
<p>This could be a neighbor, a community member, a GoFundMe campaign, a foodbank, non-profit or any number of organizations that will be impacted. Given the widespread impact of COVID we are not asking you to donate money to a specific group or organization, but that you look into your local communities and find a cause that is meaningful to you. If you are not sure where to donate, we can send out a follow-up email with some initiatives in your locality which you may want to consider for your donation.</p>


    
<p><span className="imp"> Take the pledge and invite others to do the same. In difficult times like these, we need to make sure we all look out for each other. </span></p>
 </section>   </Col>
  );
};

export default IntroDescription;
