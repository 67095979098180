import React, {useState} from 'react';
import { validate } from '../../shared/Validators'

import './Input.css';

const Input = props => {
  const [isTouched, setTouched] = useState(false)
  const [enteredValue, setValue] = useState('')
  const [isValid, setValid] = useState(validate(enteredValue, props.validators))
  
  const touchHandler = event =>{
    setTouched(true)
    let validval = validate(enteredValue, props.validators)
    setValid(validval)
    props.onInputChange(validval, enteredValue)

  }

  const valChangeHandler = event => {
    setValue(event.target.value)
    let validval = validate(event.target.value, props.validators)
    setValid(validval)
    props.onInputChange(validval, event.target.value)
  }
  
  let formcontent;
  if (props.id==="state"){
    formcontent=
    <div className={`input ${isTouched && !isValid ? "invalid" : ''}`}>
    <label>{props.label}</label>
    <select value={enteredValue} onChange={valChangeHandler}>
      {props.data.map((stateabbrev, i) => <option key={i} value={stateabbrev}>{stateabbrev}</option>)}
    </select>
   <div className={`justify-content-center ${isTouched && !isValid ? "error": "noerror"}`}> <p>Please enter a valid {props.errorText}</p></div>

  </div>
  }
  else {
    formcontent = <div className={`input ${isTouched && !isValid ? "invalid" : ''}`}>
    <label htmlFor={props.id}>{props.label}</label>
    <input
      type={props.type}
      step={props.step}
      id={props.id}
      value={enteredValue}
      onBlur={touchHandler}
      onChange={valChangeHandler}
    />
   <div className={`justify-content-center ${isTouched && !isValid ? "error": "noerror"}`}> <p>Please enter a valid {props.errorText}</p></div>

  </div>


  }
  return (
    formcontent
  );
};

export default Input;
