import React, { useState }  from 'react';
import {Row, Container} from 'react-bootstrap'
import PledgeItem from './PledgeItem'
import './PledgeList.css';

const PledgeList = props => {
  const [listedItems, setNumListedItems] = useState(20);

  const updateNumListedItems = event => {
    setNumListedItems(listedItems*2)
  }
  let content;
  let seemorebutton;
  if (props.items.length > 0) {
    const reverselist = [...props.items].reverse()
    content = (
        <Container className="pledge-list">
        <Row className="justify-content-center"><h2>Latest Pledges:</h2></Row>
        <Row className="justify-content-center">
        {reverselist.map((p, index) => {
          if (index<listedItems){
            return (<PledgeItem key={index} name={p.name} pledge={p.pledge} org={p.org} />)}
          return ''})}
      </Row>
      </Container>
    );
  }
  if (props.items.length > listedItems) {
    seemorebutton =  <Row className="justify-content-center seemore"><button onClick={updateNumListedItems}>See More Pledges</button></Row>
  }
  return <section id="pledges">
    {content}  
  {seemorebutton} 
  </section>;
};

export default PledgeList;
